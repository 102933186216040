<template>
  <h1
    v-if="title"
    class="text-h3 font-weight-bold text-center text-md-left"
  >
    {{ title }}
  </h1>
</template>

<script>
export default {
  name: 'NFTListingTitle',
  props: {
    title: {
      type: String,
      required: true,
      default: () => (''),
    },
  },
};
</script>
